/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  font-family: "PT Sans", sans-serif;
}
body {
  margin: 0;
}

.content-wrapper {
  padding: 40px 15%;

  h1 {
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
    font-size: xx-large;
  }
}
